import styled from 'styled-components'
import mq from 'styles/mq'

export const Wrapper = styled.div`
  text-align: center;
  padding: 3rem 3rem 5rem;
`

export const Lead = styled.p`
  margin: 2rem 0 3rem;

  ${mq.large} {
    margin: 2rem 5rem 3rem;
  }
`

export const Image = styled.div`
  max-width: 28rem;
  margin: 0 auto;
`
