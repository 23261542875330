import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from 'components/layout'
import SEO from 'components/seo'
import SubscribeForm from 'components/subscribe-form'
import { Wrapper, Image, Lead } from './styles'

const Newsletter = () => {
  return (
    <Layout>
      <SEO title="Subscribe to newsletter" />
      <Wrapper>
        <Image>
          <StaticImage src="./newsletter.jpg" alt="Newsletter" />
        </Image>
        <h1>Subscribe to the newsletter</h1>
        <Lead>
          Get best programming tutorials directly to your inbox.
          <br />
          Once every few weeks:
        </Lead>
        <SubscribeForm list="common" />
      </Wrapper>
    </Layout>
  )
}

export default Newsletter
